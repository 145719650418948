<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto">
          <v-list subheader two-line>
            <v-divider inset></v-divider>
            <v-list-item v-for="file in files" :key="file.title">
              <v-list-item-content>
                <v-list-item-title v-text="file.title"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="file.subtitle"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  :loading="loader"
                  :disabled="loader"
                  color="#EF515B"
                  class="ma-2 white--text"
                  @click="load()"
                >
                  Load
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Main } from "@/services/modules";
export default {
  data: () => ({
    loader: false,
    files: [
      {
        color: "#EF515B",
        title: "Load the schema for the system",
      },
    ],
  }),
  // watch: {
  //   loader() {
  //     this.loader = null;
  //   },
  // },
  methods: {
    async load() {
      this.loader = true;
      await Main.loadSchema()
        .then((response) => {
          console.log(response);
          let message;
          if (response.data.versions.success.existingVersion.length > 0) {
            message = response.data.versions.success.existingVersion[0];
          } else {
            message = response.data.versions.success.newVersion[0];
          }
          this.$store.commit("snackbar/setSnack", `Current Version ${message}`);
          this.loader = false;
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
        });
    },
  },
};
</script>
